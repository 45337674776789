.slick-slide > div {
    width: 100%;
}

.Carousel__CardImage-sc-9nbzec-5 {
    /* height: 80vh !important; */
    background-position: bottom;
}

@media only screen and (max-width: 900px) {
    div[data-index="0"] div div{
        background-position: right !important;
    }
    div[data-index="8"] div div{
        background-position: right !important;
    }
    div[data-index="4"] div div{
        background-position: right !important;
    }
    div[data-index="6"] div div{
        background-position: right !important;
    }
    div[data-index="7"] div div{
        background-position: right !important;
    }
    div[data-index="-1"] div div{
        background-position: right !important;
    }
}

/* div[data-index="2"] div div{
    background-position: bottom left !important;
} */

.Grid___StyledDiv-sc-10u6u2m-1 {
    max-width: 1024px;
}